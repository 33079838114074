<template>
  <div>
    <v-row justify="center" class="mt-4" v-if="publicdata.verein">
      <v-col cols="auto" class="text-center" align-self="center">
        <v-img width="50px" :src="publicdata.verein.verein.logo" />
      </v-col>
      <v-col cols="auto" align-self="center">
        <h2 class="white--text text-center">
          {{ publicdata.verein.verein.name }}
        </h2>
        <v-btn
          text
          rounded
          large
          @click="change_place = true"
          v-if="publicdata.sportstaette && publicdata.verein.sportstaetten"
        >
          <v-icon class="mr-2">mdi-stadium-variant</v-icon>
          {{ publicdata.sportstaette.name }}
          <v-icon small class="ml-2">mdi-swap-horizontal</v-icon>
        </v-btn>
        <v-dialog
          :fullscreen="$vuetify.breakpoint.smAndDown"
          :max-width="$vuetify.breakpoint.mdAndUp ? '600' : undefined"
          :content-class="
            $vuetify.breakpoint.smAndDown ? undefined : 'rounded-xl'
          "
          v-model="change_place"
        >
          <v-card
            :rounded="$vuetify.breakpoint.smAndDown ? undefined : 'xl'"
            class="pa-0"
          >
            <v-toolbar :color="template.colors.primary">
              <v-toolbar-title>SPORTSTÄTTE ÄNDERN</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn text @click="change_place = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-row justify="center" class="mt-4">
              <v-col cols="10" class="mb-5 text-center">
                <h3>{{ publicdata.verein.verein.name }}</h3>
                <h2>SPORTSTÄTTE WÄHLEN</h2>
              </v-col>
              <v-col
                cols="10"
                v-for="(place, index) in publicdata.verein.sportstaetten"
                :key="index"
              >
                <v-card
                  rounded="xl"
                  class="pa-2"
                  :color="
                    place.link == publicdata.sportstaette.link
                      ? template.colors.primary
                      : template.colors.blocks
                  "
                  :light="
                    place.link == publicdata.sportstaette.link
                      ? !template.isDark(template.colors.primary)
                      : !template.isDark(template.colors.blocks)
                  "
                  :dark="
                    place.link == publicdata.sportstaette.link
                      ? template.isDark(template.colors.primary)
                      : template.isDark(template.colors.blocks)
                  "
                  :href="
                    place.link == publicdata.sportstaette.link
                      ? undefined
                      : !place.main
                      ? '/' + $route.params.verein + '/' + place.link
                      : '/' + $route.params.verein
                  "
                  @click="
                    place.link == publicdata.sportstaette.link
                      ? (change_place = false)
                      : undefined
                  "
                >
                  <v-row>
                    <v-col cols="auto" align-self="center">
                      <v-avatar><v-icon>mdi-stadium-variant</v-icon></v-avatar>
                    </v-col>
                    <v-col align-self="center">
                      <h3>{{ place.name }}</h3>
                      <p
                        class="font-weight-light mb-0"
                        v-if="place.link == publicdata.sportstaette.link"
                      >
                        AUSGEWÄHLT
                      </p>
                    </v-col>
                    <v-col
                      cols="auto"
                      align-self="center"
                      v-if="place.link != publicdata.sportstaette.link"
                    >
                      <v-icon large>mdi-chevron-right</v-icon>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-btn
          @click="showhygiene = true"
          :color="template.colors.primary"
          class="rounded-xl"
        >
          <v-icon class="mr-2">mdi-hand-wash</v-icon>
          Hygienekonzept ansehen
        </v-btn>
        <Hygienekonzept v-if="showhygiene" @closing="showhygiene = false" />
      </v-col>
      <v-col cols="12" class="text-center mb-0">
        <v-divider class="mt-2 mb-4"></v-divider>
        <h2>PERSONENERFASSUNG</h2>
        <h4 class="font-weight-bold text-uppercase">
          Bitte wähle aus, wie deine Daten erfasst werden sollen:
        </h4>
        <v-divider class="mt-4"></v-divider>
      </v-col>
      <v-col cols="12" md="10" lg="8" class="mt-0">
        <v-card
          class="my-3 pa-2 rounded-xl"
          color="grey darken-3"
          link
          @click="anonym()"
          :loading="loading"
        >
          <v-row justify="center">
            <v-col cols="auto" class="ml-2" align-self="center">
              <v-icon large>mdi-card-account-details</v-icon>
            </v-col>

            <v-col align-self="center">
              <h3>
                Einmalige Erfassung
              </h3>
              <small>
                Deine Daten werden nur für eine bestimmte Veranstaltung erfasst
                und nicht permanent gespeichert. Du musst deine Daten für jede
                weitere Veranstaltung eingeben.
              </small>
            </v-col>
          </v-row>
        </v-card>

        <v-card
          class="my-3 pa-2 rounded-xl"
          color="success"
          link
          @click="zuschauerlogin = true"
        >
          <v-row justify="center">
            <v-col cols="auto" class="ml-2" align-self="center">
              <v-icon large>mdi-account-key</v-icon>
            </v-col>

            <v-col align-self="center">
              <v-row>
                <v-col cols="12" align-self="center" class="pb-0">
                  <h3>
                    Login
                  </h3>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <small>
                    Wenn du bereits einen Account hast, dann kannst du dich
                    einloggen und dich in die Anwesenheitsliste eintragen.
                  </small>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>

        <v-card
          class="my-3 pa-2 rounded-xl"
          :color="template.colors.primary"
          :style="`color: ${template.colors.inline_primary_text};`"
          @click="newzuschauer = true"
        >
          <v-row justify="center">
            <v-col cols="auto" class="ml-2" align-self="center">
              <v-icon large>mdi-account-plus</v-icon>
            </v-col>

            <v-col align-self="center">
              <h3>
                Registrierung
              </h3>
              <small>
                Erstelle einen "Zuschauer-Account" und trage dich zukünftig
                innerhalb von Sekunden in die Anwesenheitslisten von
                Veranstaltungen ein. Und zwar Vereinsübergreifend - bei allen
                Veranstaltungen, wo {{ template.var.full_app_name }} zur
                Personenerfassung verwendet wird.
              </small>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          class="my-3 pa-2 rounded-xl"
          :color="template.colors.blocks"
          :style="`color: ${template.colors.block_text};`"
          to="/schiedsrichter-registrierung"
        >
          <v-row justify="center">
            <v-col cols="auto" class="ml-2" align-self="center">
              <v-icon :color="template.colors.block_text" large>
                mdi-whistle
              </v-icon>
            </v-col>

            <v-col align-self="center">
              <h3>
                Registrierung als Schiedsrichter
              </h3>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-4" v-if="!publicdata.verein">
      <v-col
        cols="12"
        class="text-center mb-4"
        v-if="publicdata.nodata == false"
      >
        <h2>Verein wird geladen</h2>
        <v-progress-circular
          :color="template.colors.primary"
          circle
          indeterminate
        ></v-progress-circular>
      </v-col>
      <v-col
        cols="12"
        class="text-center mb-4"
        v-if="publicdata.nodata == true"
      >
        <h2>Seite nicht gefunden</h2>
        <p>
          Dein eingegebener Link scheint fehlerhaft zu sein. Bitte überprüfe
          deine Eingabe und versuche es erneut.
        </p>
      </v-col>
    </v-row>
    <Registrierung v-if="newzuschauer" v-on:closing="newzuschauer = false" />
    <Login v-if="zuschauerlogin" v-on:closing="zuschauerlogin = false" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '../../store'
//import firebase from '../../firebaseConfig'
import router from '../../routes/index'
import Registrierung from './registrierung.vue'
import Login from './login.vue'
import Hygienekonzept from './hygienekonzept.vue'

export default {
  name: 'Mein-Verein',
  data() {
    return {
      showhygiene: false,
      newzuschauer: false,
      zuschauerlogin: false,
      loading: false,
      verbaende: [],
      change_place: false,
    }
  },
  computed: {
    ...mapGetters({
      publicdata: 'publicdata',
      user: 'user',
      template: 'template',
    }),
  },
  components: {
    Registrierung,
    Login,
    Hygienekonzept,
  },
  mounted() {
    if (
      this.$route.params.verein == 'undefined' ||
      !this.$route.params.verein
    ) {
      router.push('/')
    } else if (this.user.data && firebase.auth().currentUser) {
      if (this.user.zuschauerdaten) {
        if (this.$route.params.sportstaette) {
          router.push(
            '/' +
              this.$route.params.verein +
              '/' +
              this.$route.params.sportstaette +
              '/erfassen',
          )
        } else {
          router.push('/' + this.$route.params.verein + '/erfassen')
        }
      } else {
        if (this.$route.params.sportstaette) {
          router.push(
            '/' +
              this.$route.params.verein +
              '/' +
              this.$route.params.sportstaette +
              '/vervollstaendigen',
          )
        } else {
          router.push('/' + this.$route.params.verein + '/vervollstaendigen')
        }
      }
    } else {
      if (!this.publicdata.verein) {
        store.dispatch('setPublic', {
          name: this.$route.params.verein,
          sportstaette: this.$route.params.sportstaette,
        })
      } else {
        if (this.publicdata.verein.alias != this.$route.params.verein) {
          store.dispatch('setPublic', {
            name: this.$route.params.verein,
            sportstaette: this.$route.params.sportstaette,
          })
        }
      }
    }
  },
  methods: {
    anonym() {
      this.loading = true
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() => {
          // Existing and future Auth states are now persisted in the current
          // session only. Closing the window would clear any existing state even
          // if a user forgets to sign out.
          // ...
          // New sign-in will be persisted with session persistence.
          return firebase.auth().signInAnonymously()
        })
        .then(() => {
          this.loading = false
          if (this.$route.params.sportstaette) {
            router.push(
              '/' +
                this.$route.params.verein +
                '/' +
                this.$route.params.sportstaette +
                '/erfassen',
            )
          } else {
            router.push('/' + this.$route.params.verein + '/erfassen')
          }
        })
        .catch(function (error) {
          this.loading = false
          console.log(error)
        })
    },
  },
}
</script>
>
