<template>
  <v-dialog
    value="true"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    :persistent="$vuetify.breakpoint.lgAndUp"
    transition="dialog-bottom-transition"
    :max-width="$vuetify.breakpoint.lgAndUp ? '800' : undefined"
    dark
    style="padding-top: env(safe-area-inset-top);"
    content-class="rounded-xl"
  >
    <v-card class="rounded-xl" style="width: 100vw; overflow-x: hidden;">
      <v-toolbar
        fixed
        :color="template.colors.primary"
        :style="`color: ${template.colors.inline_primary_text};`"
        style="
          padding-top: env(safe-area-inset-top);
          height: 80px + env(safe-area-inset-top);
        "
      >
        <v-toolbar-title>BITTE BEACHTEN</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closing()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-row justify="center" class="mt-4 px-3">
        <v-col
          cols="12"
          class="text-center"
          v-if="publicdata.verein.hygienekonzept"
        >
          <h1>
            {{
              publicdata.verein.hygienekonzept.custom_header.activated
                ? publicdata.verein.hygienekonzept.custom_header.title
                : 'HYGIENE REGELN'
            }}
          </h1>
          <h3 v-if="!publicdata.verein.hygienekonzept.custom_header.activated">
            {{ publicdata.verein.verein.name }}
          </h3>
          <h4 v-if="publicdata.verein.hygienekonzept.custom_header.activated">
            {{ publicdata.verein.hygienekonzept.custom_header.text }}
          </h4>
          <v-btn
            block
            rounded
            class="my-2"
            :color="template.colors.primary"
            :href="publicdata.verein.hygienekonzept.custom_header.link"
            target="_blank"
            v-if="publicdata.verein.hygienekonzept.custom_header.link"
          >
            {{
              publicdata.verein.hygienekonzept.custom_header.linkname
                ? publicdata.verein.hygienekonzept.custom_header.linkname
                : 'Website öffnen'
            }}
            <v-icon class="ml-2">mdi-open-in-new</v-icon>
          </v-btn>
          <v-card
            :color="template.colors.blocks"
            :style="`color: ${template.colors.block_text};`"
            class="rounded-xl mt-3 mb-4"
            v-if="publicdata.verein.hygienekonzept.testpflicht"
          >
            <v-row class="pa-4">
              <v-col cols="4" md="3" class="text-center">
                <v-img src="/img/covid-icons/icon_3g.png"></v-img>
              </v-col>
              <v-col cols="8" md="9" class="text-left" align-self="center">
                <h3>
                  {{
                    publicdata.verein.hygienekonzept.ggg_regel
                      ? publicdata.verein.hygienekonzept.ggg_regel.titel
                      : '3G-Regel'
                  }}
                </h3>
                <span>
                  Es ist zwingend ein negativer COVID-19 Test (nicht älter als
                  48h), ein Nachweis über eine vollständige COVID-19 Impfung
                  oder ein Nachweis über eine Genesung von COVID-19 (nicht älter
                  als 6 Monate).
                </span>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            :color="template.colors.blocks"
            :style="`color: ${template.colors.block_text};`"
            class="rounded-xl mt-3 mb-4"
            v-if="publicdata.verein.hygienekonzept.abstand.activated"
          >
            <v-row class="pa-4">
              <v-col cols="4" md="3" class="text-center">
                <v-img src="/img/covid-icons/abstand.png"></v-img>
              </v-col>
              <v-col cols="8" md="9" class="text-left" align-self="center">
                <h3>{{ publicdata.verein.hygienekonzept.abstand.title }}</h3>
                <span>
                  {{ publicdata.verein.hygienekonzept.abstand.text }}
                </span>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            :color="template.colors.blocks"
            :style="`color: ${template.colors.block_text};`"
            class="rounded-xl mt-3 mb-4"
            v-if="publicdata.verein.hygienekonzept.bereiche.activated"
          >
            <v-row class="pa-4">
              <v-col cols="4" md="3" class="text-center">
                <v-img src="/img/covid-icons/bereiche.png"></v-img>
              </v-col>
              <v-col cols="8" md="9" class="text-left" align-self="center">
                <h3>{{ publicdata.verein.hygienekonzept.bereiche.title }}</h3>
                <span>
                  {{ publicdata.verein.hygienekonzept.bereiche.text }}
                </span>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            :color="template.colors.blocks"
            :style="`color: ${template.colors.block_text};`"
            class="rounded-xl mt-3 mb-4"
            v-if="publicdata.verein.hygienekonzept.desinfektion.activated"
          >
            <v-row class="pa-4">
              <v-col cols="4" md="3" class="text-center">
                <v-img src="/img/covid-icons/haende.png"></v-img>
              </v-col>
              <v-col cols="8" md="9" class="text-left" align-self="center">
                <h3>
                  {{ publicdata.verein.hygienekonzept.desinfektion.title }}
                </h3>
                <span>
                  {{ publicdata.verein.hygienekonzept.desinfektion.text }}
                </span>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            :color="template.colors.blocks"
            :style="`color: ${template.colors.block_text};`"
            class="rounded-xl mt-3 mb-4"
            v-if="publicdata.verein.hygienekonzept.kontakte.activated"
          >
            <v-row class="pa-4">
              <v-col cols="4" md="3" class="text-center">
                <v-img src="/img/covid-icons/no_handshake.png"></v-img>
              </v-col>
              <v-col cols="8" md="9" class="text-left" align-self="center">
                <h3>
                  {{ publicdata.verein.hygienekonzept.kontakte.title }}
                </h3>
                <span>
                  {{ publicdata.verein.hygienekonzept.kontakte.text }}
                </span>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            :color="template.colors.blocks"
            :style="`color: ${template.colors.block_text};`"
            class="rounded-xl mt-3 mb-4"
            v-if="publicdata.verein.hygienekonzept.maske.activated"
          >
            <v-row class="pa-4">
              <v-col cols="4" md="3" class="text-center">
                <v-img src="/img/covid-icons/maske.png"></v-img>
              </v-col>
              <v-col cols="8" md="9" class="text-left" align-self="center">
                <h3>
                  {{ publicdata.verein.hygienekonzept.maske.title }}
                </h3>
                <span>
                  {{ publicdata.verein.hygienekonzept.maske.text }}
                </span>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            :color="template.colors.blocks"
            :style="`color: ${template.colors.block_text};`"
            class="rounded-xl mt-3 mb-4"
            v-if="publicdata.verein.hygienekonzept.symptome.activated"
          >
            <v-row class="pa-4">
              <v-col cols="4" md="3" class="text-center">
                <v-img src="/img/covid-icons/healthy.png"></v-img>
              </v-col>
              <v-col cols="8" md="9" class="text-left" align-self="center">
                <h3>
                  {{ publicdata.verein.hygienekonzept.symptome.title }}
                </h3>
                <span>
                  {{ publicdata.verein.hygienekonzept.symptome.text }}
                </span>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          class="text-center"
          v-if="!publicdata.verein.hygienekonzept"
        >
          <h1>HYGIENE REGELN</h1>
          <v-card
            :color="template.colors.blocks"
            :style="`color: ${template.colors.block_text};`"
            class="rounded-xl mt-3 mb-4"
          >
            <v-row class="pa-4">
              <v-col cols="4" md="3" class="text-center">
                <v-img src="/img/covid-icons/maske.png"></v-img>
              </v-col>
              <v-col cols="8" md="9" class="text-left" align-self="center">
                <h3>MUND- & NASENSCHUTZ</h3>
                <span>
                  Trage auf dem Gelände und vor allem in geschlossenen Räumen
                  stets einen Mund- & Nasenschutz
                </span>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            :color="template.colors.blocks"
            :style="`color: ${template.colors.block_text};`"
            class="rounded-xl mt-3 mb-4"
          >
            <v-row class="pa-4">
              <v-col cols="4" md="3" class="text-center">
                <v-img src="/img/covid-icons/abstand.png"></v-img>
              </v-col>
              <v-col cols="8" md="9" class="text-left" align-self="center">
                <h3>HALTE ABSTAND</h3>
                <span>
                  Achte auf den Mindestabstand - auch im Zuschauerbereich
                </span>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            :color="template.colors.blocks"
            :style="`color: ${template.colors.block_text};`"
            class="rounded-xl my-4"
          >
            <v-row class="pa-4">
              <v-col cols="4" md="3" class="text-center" align-self="center">
                <v-img src="/img/covid-icons/haende.png"></v-img>
              </v-col>
              <v-col cols="8" md="9" class="text-left" align-self="center">
                <h3>HÄNDE DESINFIZIEREN</h3>
                <span>
                  Desinfiziere deine Hände beim Betreten & Verlassen des
                  Geländes oder wasche dir für min. 20 Sekunden deine Hände
                </span>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            :color="template.colors.blocks"
            :style="`color: ${template.colors.block_text};`"
            class="rounded-xl mt-3 mb-4"
          >
            <v-row class="pa-4">
              <v-col cols="4" md="3" class="text-center">
                <v-img src="/img/covid-icons/healthy.png"></v-img>
              </v-col>
              <v-col cols="8" md="9" class="text-left" align-self="center">
                <h3>FÜHLST DU DICH FIT?</h3>
                <span>
                  Bitte bleibe zu Hause, wenn du dich krank fühlst.
                </span>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            :color="template.colors.blocks"
            :style="`color: ${template.colors.block_text};`"
            class="rounded-xl my-4"
          >
            <v-row class="pa-4">
              <v-col cols="4" md="3" class="text-center" align-self="center">
                <v-img src="/img/covid-icons/no_handshake.png"></v-img>
              </v-col>
              <v-col cols="8" md="9" class="text-left" align-self="center">
                <h3>VERMEIDE KONTAKT</h3>
                <span>
                  Verzichte auf übliche Begrüßungsrituale und Körperkontakt zu
                  anderen Personen
                </span>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
//import firebase from '../../firebaseConfig'
import { mapGetters } from 'vuex'

export default {
  name: 'Sportstätte-erstellen',
  data() {
    return {
      sportstaette: {
        name: '',
        link: '',
      },
      linkexist: '',
      linkok: '',
      creating: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      publicdata: 'publicdata',
      template: 'template',
    }),
  },
  methods: {
    closing() {
      this.$emit('closing', true)
    },
  },
}
</script>
