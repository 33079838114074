<template>
  <v-dialog
    value="true"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    :persistent="$vuetify.breakpoint.lgAndUp"
    transition="dialog-bottom-transition"
    :max-width="$vuetify.breakpoint.lgAndUp ? '800' : undefined"
    dark
    content-class="rounded-xl"
    style="padding-top: env(safe-area-inset-top);"
  >
    <v-card class="rounded-xl" style="width: 100vw; overflow-x: hidden;">
      <v-toolbar
        fixed
        :color="template.colors.primary"
        :style="`color: ${template.colors.inline_primary_text};`"
        style="
          padding-top: env(safe-area-inset-top);
          height: 80px + env(safe-area-inset-top);
        "
      >
        <v-toolbar-title>Registrierung</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closing()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-row justify="center" style="min-height: 50vh;">
        <v-col cols="12" v-if="art == 'email'">
          <h2 class="text-center my-5">Mit E-Mail-Adresse registrieren</h2>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row justify="center">
              <v-col cols="11" md="10" class="py-0">
                <v-text-field
                  filled
                  rounded
                  label="E-Mail Adresse"
                  :rules="[rules.required, rules.email]"
                  v-model="v.email"
                ></v-text-field>
              </v-col>
              <v-col cols="11" md="5" class="py-0">
                <v-text-field
                  filled
                  rounded
                  type="password"
                  label="Passwort"
                  :rules="[rules.required, rules.password]"
                  v-model="v.password"
                ></v-text-field>
              </v-col>
              <v-col cols="11" md="5" class="py-0">
                <v-text-field
                  filled
                  rounded
                  type="password"
                  label="Passwort bestätigen"
                  :rules="[rules.required, rules.passwordconfirm]"
                  v-model="v.passwordconfirm"
                ></v-text-field>
              </v-col>
              <v-col cols="11" md="5" class="py-0">
                <v-checkbox v-model="v.confirm_privacy" label="">
                  <template slot="label">
                    <span>
                      Ich habe die
                      <a
                        @click.stop
                        :href="
                          template.var.datenschutz
                            ? template.var.datenschutz.link
                            : '/datenschutz'
                        "
                        target="_blank"
                      >
                        Datenschutzinformationen
                      </a>
                      zur Kenntnis genommen
                    </span>
                  </template>
                </v-checkbox>
              </v-col>
              <v-col cols="11" class="py-0 text-center mt-5">
                <v-btn
                  :color="template.colors.primary"
                  :style="`color: ${template.colors.inline_primary_text};`"
                  class="my-5"
                  :block="$vuetify.breakpoint.smAndDown"
                  large
                  :disabled="
                    !v.confirm_privacy ||
                    !v.email ||
                    !v.password ||
                    !v.passwordconfirm ||
                    v.password != v.passwordconfirm
                  "
                  rounded
                  @click="create()"
                  :loading="loading"
                >
                  Jetzt registrieren
                </v-btn>
                <v-btn
                  :color="template.colors.primary"
                  :style="`color: ${template.colors.inline_primary_text};`"
                  :class="$vuetify.breakpoint.smAndDown ? 'my-5' : 'ma-2'"
                  outlined
                  rounded
                  :block="$vuetify.breakpoint.smAndDown"
                  large
                  @click="art = ''"
                >
                  Abbrechen
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col cols="10" v-if="!art" align-self="center">
          <h2 class="text-center">Wie möchtest du dich registrieren?</h2>
        </v-col>
        <v-col cols="auto" v-if="!art" align-self="center">
          <v-card
            color="white"
            light
            class="my-5 pa-0 rounded-xl"
            link
            @click="art = 'email'"
          >
            <v-row justify="center" class="pa-0">
              <v-col cols="12" align-self="center" class="py-2">
                <h3>
                  <v-icon class="mx-2" large>mdi-at</v-icon>
                  Mit E-Mail-Adresse registrieren
                </h3>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            color="#4285F4"
            dark
            link
            class="my-5 pa-0 rounded-xl"
            @click="g_signup()"
          >
            <v-row class="pa-0">
              <v-col cols="12" align-self="center" class="py-2">
                <h3>
                  <v-icon class="mx-2" large>mdi-google</v-icon>
                  Mit Google registrieren
                </h3>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            color="#3b5998"
            link
            class="my-5 pa-0 rounded-xl"
            @click="fb_signup()"
            v-if="false"
          >
            <v-row justify="center" class="pa-0 pr-2">
              <v-col cols="12" align-self="center" class="py-2">
                <h3>
                  <v-icon class="mx-2" large>mdi-facebook</v-icon>
                  Mit Facebook registrieren
                </h3>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            color="#00acee"
            link
            class="my-5 pa-0 rounded-xl"
            @click="twitter_signup()"
          >
            <v-row justify="center" class="pa-0 pr-2">
              <v-col cols="12" align-self="center" class="py-2">
                <h3>
                  <v-icon class="mx-2" large>mdi-twitter</v-icon>
                  Mit Twitter registrieren
                </h3>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            color="#FFFFFF"
            light
            link
            class="my-5 pa-0"
            @click="ms_signup()"
            v-if="false"
          >
            <v-row class="pa-0">
              <v-col
                cols="auto"
                class="py-0 ml-1 mr-0 pr-0"
                align-self="center"
              >
                <v-img src="/img/microsoft-login.svg" width="40" />
              </v-col>
              <v-col align-self="center">
                <h3>Mit Microsoft-Konto registrieren</h3>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-snackbar
      :color="template.colors.primary"
      :dark="!template.light"
      bottom
      v-model="error"
    >
      {{ errortext }}
    </v-snackbar>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '../../store'
//import firebase from '../../firebaseConfig'

export default {
  name: 'Mein-Verein',
  data() {
    return {
      art: '',
      v: {
        vorname: '',
        nachname: '',
        anschrift: '',
        plz: '',
        ort: '',
        telefon: '',
        email: '',
        password: '',
        passwordconfirm: '',
        confirm_privacy: false,
      },
      valid: false,
      error: false,
      errortext: '',
      loading: false,
      rules: {
        required: (value) => !!value || 'Bitte gebe diese Daten ein',
        name: (v) =>
          v.length >= 2 ? true : 'Bitte gebe einen gültigen Namen ein',
        password: (v) =>
          v.length >= 6
            ? true
            : 'Dein Passwort sollte mindestens 6 Zeichen lang sein',
        passwordconfirm: (v) =>
          v == this.v.password ? true : 'Die Passwörter stimmen nicht überein',
        street: (v) =>
          v.length >= 5 ? true : 'Bitte gebe einen gültigen Straßennamen ein',
        streetnr: (v) =>
          v.match(/[0-9]{1,}/)
            ? true
            : 'Bitte gebe eine gültige Hausnummer ein',
        telefon: (v) =>
          v.match(/[0-9]{5,}/)
            ? true
            : 'Bitte gebe eine gültige Telefonnummer ein',
        plz: (v) =>
          v.match(/[0-9]{5}/)
            ? true
            : 'Bitte gebe eine gültige Postleitzahl ein',
        email: (v) =>
          v.match(
            /^((?!\.)[\w-_.]*[^.])(@\w+)((?!\.)[\w-_.]*[^.])(\.\w+(\.\w+)?[^.\W])$/,
          )
            ? true
            : 'Bitte gebe eine gültige E-Mail Adresse ein',
      },
    }
  },
  computed: {
    ...mapGetters({
      publicdata: 'publicdata',
      template: 'template',
    }),
  },
  mounted() {
    if (!this.publicdata.verein) {
      store.dispatch('setPublic', {
        alias: this.$route.params.verein,
        sportstaette: this.$route.params.sportstaette,
      })
    } else {
      if (this.publicdata.verein.alias != this.$route.params.verein) {
        store.dispatch('setPublic', {
          alias: this.$route.params.verein,
          sportstaette: this.$route.params.sportstaette,
        })
      }
    }
  },
  methods: {
    closing() {
      this.$emit('closing', true)
    },
    g_signup() {
      var provider = new firebase.auth.GoogleAuthProvider()
      firebase.auth().languageCode = 'de'
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          var token = result.credential.accessToken
          // The signed-in user info.
          var user = result.user
          // ...
        })
        .catch((error) => {
          this.error = error
          this.errortext = error.message
          console.log(error)
        })
    },
    fb_signup() {
      var provider = new firebase.auth.FacebookAuthProvider()
      //provider.addScope('profile_pic')
      firebase.auth().languageCode = 'de'
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          var token = result.credential.accessToken
          // The signed-in user info.
          var user = result.user
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          this.error = error
          this.errortext = error.message
          console.log(error)
        })
    },
    twitter_signup() {
      var provider = new firebase.auth.TwitterAuthProvider()
      //provider.addScope('profile_pic')
      firebase.auth().languageCode = 'de'
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          var token = result.credential.accessToken
          // The signed-in user info.
          var user = result.user
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          this.error = error
          this.errortext = error.message
          console.log(error)
        })
    },
    ms_signup() {
      var provider = new firebase.auth.OAuthProvider('microsoft.com')
      //provider.addScope('profile_pic')
      firebase.auth().languageCode = 'de'
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          var token = result.credential.accessToken
          // The signed-in user info.
          var user = result.user
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          this.error = error
          this.errortext = error.message
          console.log(error)
        })
    },
    create() {
      this.loading = true
      if (this.$refs.form.validate()) {
        firebase
          .auth()
          .createUserWithEmailAndPassword(this.v.email, this.v.password)
          .catch((error) => {
            this.error = error
            this.errortext = error.message
            this.loading = false
          })
      }
    },
  },
}
</script>
>
